<script>
	import BaseVue from '@frontend/Base.vue'
	import Gen from '../helper/Gen';
	import swal from 'sweetalert';

	export default {
		extends: BaseVue,
		data() {
			return {
				slider: false,
				input: {},
				filter: {},
				tab: {},
				data: false,
			}
		},
		computed: {

		},
		async mounted() {
			await this.ready();
			this.getSlider()
			this.getListPertanyaan()
		},
		methods: {
			getSlider() {
				$("#FoMain #wrapper > *:not(#header)").css("opacity", 0)
				Gen.apirest("/static-hero", {
					id: 5
				}, (err, resp) => {
					this.slider = resp.data
					setTimeout(() => {
						global.SEMICOLON.initialize.fullScreen()
						Gen.loadScript("/frontend/js/custom.js", false)
						$("#FoMain #wrapper > *:not(#header)").css("opacity", 1)
						this.focusContent()
					}, 100)
				})
			},
			getListPertanyaan() {
				this.data = false
				let params = Gen.clone(this.$route.query)
				Gen.apirest("/pertanyaan-list", params, (err, resp) => {
					this.data = resp.data
				})
			},
			submit() {
				this.loadingOverlay = true
				Gen.apirest("/submit-pertanyaan", this.input, (err) => {
					this.loadingOverlay = false
					if (err) {
						swal("Warning!", err.resp.message, "warning")
						return;
					}
					swal("Sukses", "Pertanyaan Anda berhasil terkirim.", "success")
					this.input = {}
					this.tab = ''
				}, "POST")
			},
			search() {
				this.$router.push({
					query: {
						search: this.filter.search
					}
				})
			},
			changePage(page = 1) {
				this.$router.push({
					query: Object.assign(Gen.clone(this.$route.query), {
						page: page
					})
				})
			},
			focusContent() {
				if (this.$route.query.applyForm) {
					this.tab = 'makeQuestion'
					setTimeout(() => {
						$('body,html').animate({
							scrollTop: $("#makeQuestion").offset().top - 100
						}, 300)
					}, 100)
				}
			},
			setDocFoc(){
				setTimeout(() => {
					$(".form-control#docSearch").focus();
				}, 500);
			}
		},
		watch: {
			$route() {
				this.getListPertanyaan()
			}
		},
	};
</script>
<template>
	<fragment>
		<section id="slider" v-if="slider" class="slider-element  half-slider force-full-screen full-screen clearfix"
			data-loop="true" data-autoplay="5000">
			<div class="force-full-screen full-screen"
				:style="'background-image: url(\''+uploader(isMobile?slider.ash_mobile_img:slider.ash_img)+'\');'">
			</div>
		</section>

		<!-- Content
		============================================= -->
		<section id="content">
			<div class="content-wrap">
				<section class="section nobg nomargin">
					<div class="container">
						<div class="row justify-content-center">
							<div class="col-lg-9">
								<div class="question-form">
									<div class="qf-cta">
										<a href="javascript:;" @click="tab=(tab=='makeQuestion'?'':'makeQuestion')" class="primary-btn q-toggler mr-md-2" :class="tab=='makeQuestion' ? 'active':''">Ajukan Pertanyaan</a>
										<a href="javascript:;" @click="tab=(tab=='searchQuestion'?'':'searchQuestion');setDocFoc();"
											class="box-btn q-toggler" :class="tab=='searchQuestion' ? 'active':''" id="qBtn">Cari Pertanyaan</a>
									</div>
									<div class="overflow-hidden">
										<transition name="custom" enter-active-class="animated fadeInDown"
											leave-active-class="animated fadeOutUp">
											<VForm @resp="submit" v-if="tab=='makeQuestion'" id="makeQuestion">
												<div class="row">
													<div class="col-12">
														<div class="alert alert-info">
															Dokter pilihan Tropicana Slim akan menjawab pertanyaan Anda
															melalui
															email. Pertanyaan terpilih akan kami tampilkan di halaman
															ini.
														</div>
													</div>
													<div class="col-md-6">
														<div class="form-group float-label">
															<input type="text" name="Nama" v-model="input.ad_fullname"
																id="docName" class="form-control" required>
															<label for="docName">Nama<span
																	class="mandatory text-danger">*</span></label>
														</div>
													</div>
													<div class="col-md-6">
														<div class="form-group float-label">
															<input type="email" name="Email" v-model="input.ad_email"
																class="form-control" id="docEmail" required>
															<label for="docEmail">Email<span
																	class="mandatory text-danger">*</span></label>
														</div>
													</div>
													<div class="col-12">
														<div class="form-group float-label">
															<input type="text" name="Subjek" v-model="input.ad_subject"
																class="form-control" id="docSubject" required>
															<label for="docSubject">Subjek<span
																	class="mandatory text-danger">*</span></label>
														</div>
													</div>
													<div class="col-12">
														<div class="form-group float-label">
															<textarea name="Pertanyaan" v-model="input.ad_question"
																rows="5" class="form-control" required
																id="docQuestion"></textarea>
															<label for="docQuestion">Pertanyaan<span
																	class="mandatory text-danger">*</span></label>
														</div>
													</div>
													<div class="col-12 text-right">
														<input type="submit" value="Kirim" class="primary-btn">
													</div>
												</div>
											</VForm>
										</transition>
										<transition name="custom" enter-active-class="animated fadeInDown"
											leave-active-class="animated fadeOutUp">
											<VForm @resp="search" v-if="tab=='searchQuestion'">
												<div class="form-row">
													<div class="col-sm-10 col-10">
														<div class="form-group float-label">
															<input type="text" v-model="filter.search"
																class="form-control" id="docSearch">
															<label for="docSearch">Masukkan Kata Kunci</label>
														</div>
													</div>
													<div class="col-sm-2 col-2">
														<div class="form-group float-label text-right">
															<button type="submit" class="primary-btn" :class="isMobile ? 'px-2 btn-block':'btn-block'">
																<span v-if="!isMobile">Cari</span>
																<span v-else class="icon-search"></span>
															</button>
														</div>
													</div>
												</div>
											</VForm>
										</transition>
									</div>
								</div>
								<div class="article-list">
									<div class="d-flex justify-content-between align-items-baseline">
										<h3 onclick="setTimeout(()=>{$('#docSearch').focus()}, 500)">List Pertanyaan</h3>
										<span>{{data?data.total:''}} Pertanyaan</span>
									</div>
									<div class="text-center">
										<LoadingSpinnerFe v-if="data===false" light />
										<div v-if="data&&!data.total" class="center py-5 py-lg-0">
											<div class="row justify-content-center">
												<div class="col-md-6 col-lg-5">
													<img :src="assets('fo_images', 'empty.svg')" alt="No Results Found">
												</div>
											</div>
											<h3>{{$route.query.search?'Pencarian dengan kata kunci \''+$route.query.search+'\' tidak ditemukan.':'Belum ada pertanyaan.'}}
											</h3>
										</div>
									</div>
									<div class="article-item" v-for="(v,k) in data.data" :key="k">
										<router-link :to="{name:'DetailPertanyaan',params:{slug:v.ad_slug}}">
											<div class="article-desc">
												<div class="d-flex mb-2">
													<div class="succ-content d-none d-md-flex"
														:class="{'not-answered':v.ad_status!='Y'}">
														<div class="sc-caption"> <i class="icon-check"></i> </div>
													</div>
													<div class="ask-heading">
														<h4>{{v.ad_subject}}</h4>
														<span>Oleh : {{v.ad_fullname}}</span>
														<span class="text-success answered" v-if="v.ad_status=='Y'"><i
																class="icon-check d-inline d-md-none"></i> Pertanyaan
															Terjawab</span>
													</div>
												</div>
												<p>{{(v.ad_question).limitWord(30)}}</p>
											</div>
										</router-link>
									</div>
								</div>
								<Pagination v-if="data" :limit="2" :data="data" @pagination-change-page="changePage"
									class="pagination pagination-transparent pagination-circle justify-content-center mb-0">
									<i slot="prev-nav" class="icon-angle-left"></i>
									<i slot="next-nav" class="icon-angle-right"></i>
								</Pagination>
							</div>
						</div>
					</div>
				</section>
			</div>
		</section>
	</fragment>
</template>